const QuantityCard = ({
  label,
  color,
  onClick = null,
  value = 30,
  isSelected,
  icon = "pi pi-chart-bar",
  description
}) => {
  // Helper function to adjust color opacity
  const adjustColorOpacity = (color, opacity) => {
    return `${color}${opacity}`;
  };

  return (
    <div
      onClick={onClick}
      className={`quantity-card ${onClick ? "clickable" : ""} ${
        isSelected ? "selected" : ""
      }`}
      style={{
        borderLeft: `4px solid ${color}`
      }}
      title={description ? `${label}\n${description}` : label}
    >
      <div
        className="card-icon"
        style={{ backgroundColor: adjustColorOpacity(color, "15") }}
      >
        <i
          className={icon}
          style={{ color: adjustColorOpacity(color, "90") }}
        />
      </div>

      <div className="card-content">
        <div className="card-value" style={{ color }}>
          {value}
        </div>
        <div className="card-label">{label}</div>
        {description && (
          <div className="card-description" style={{ fontSize: 12 }}>
            {description}
          </div>
        )}
      </div>
    </div>
  );
};

export default QuantityCard;
